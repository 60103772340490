import Layout from "components/layout";
import SEO from "components/seo";
import WordCountRatio from "components/wordCountRatio";
import HelpMyself from "components/wordCountRatio/HelpMyself";
import React from "react";

const index = () => {
  const title = "Free Word Count Ratio Tool";
  const description =
    "No more guessing the word count. Turn your source word count into an accurate target word count with our free online word counter tool. Perfect for students, translators, and professionals.";
  const keywords = "Tomedes Word Count Ratio Tool";
  const slug = "/tools/word-count-ratio";
  return (
    <Layout>
      <SEO
        title={title}
        description={description}
        keywords={keywords}
        slug={slug}
      />
      {/* <WordCountRatio /> */}
      <HelpMyself />
    </Layout>
  );
};

export default index;
